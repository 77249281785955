import React, { useState } from 'react';
import { useStore } from '../store/useStore';
import { BuyerCard } from '../components/BuyerCard';
import { FilterDrawer } from '../components/FilterDrawer';
import { CreateBuyerDialog } from '../components/CreateBuyerDialog';
import { motion } from 'framer-motion';
import { Plus, Filter } from 'lucide-react';

const roleCategories = {
  'Executive Leadership': ['CIO', 'VP'],
  'Human Resources': ['Head of HR'],
  'Customer Service': ['Support Team Lead'],
  'Education': ['Education Coordinator'],
  'Recruitment': ['Technical Recruiter']
};

export function BuyersPage() {
  const { buyers } = useStore();
  const [selectedCountry, setSelectedCountry] = useState<string>('all');
  const [selectedCallType, setSelectedCallType] = useState<string>('all');
  const [selectedRole, setSelectedRole] = useState<string>('all');
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const filteredBuyers = buyers.filter(
    (buyer) =>
      (selectedCountry === 'all' || buyer.country === selectedCountry) &&
      (selectedCallType === 'all' || buyer.callType === selectedCallType) &&
      (selectedRole === 'all' || Object.entries(roleCategories).some(([category, titles]) => 
        selectedRole === category && titles.some(title => buyer.title.includes(title))
      ))
  );

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  const renderByCategory = () => {
    return Object.entries(roleCategories).map(([category, titles]) => {
      const categoryBuyers = filteredBuyers.filter(buyer => 
        titles.some(title => buyer.title.includes(title))
      );

      if (categoryBuyers.length === 0) return null;

      return (
        <motion.div
          key={category}
          className="mb-8"
          initial="hidden"
          animate="show"
          variants={container}
        >
          <h2 className="text-xl font-semibold text-gray-800 mb-4">{category}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {categoryBuyers.map((buyer) => (
              <motion.div key={buyer.id} variants={item}>
                <BuyerCard buyer={buyer} onSelect={() => {}} />
              </motion.div>
            ))}
          </div>
        </motion.div>
      );
    });
  };

  const getActiveFiltersCount = () => {
    let count = 0;
    if (selectedCountry !== 'all') count++;
    if (selectedCallType !== 'all') count++;
    if (selectedRole !== 'all') count++;
    return count;
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">AI Personas</h1>
          <p className="text-gray-600">Practice your sales calls with AI-powered personas</p>
        </div>
        <div className="flex items-center space-x-4">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setIsFilterDrawerOpen(true)}
            className="flex items-center space-x-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors relative"
          >
            <Filter size={20} />
            <span>Filters</span>
            {getActiveFiltersCount() > 0 && (
              <span className="absolute -top-2 -right-2 bg-blue-600 text-white w-6 h-6 rounded-full flex items-center justify-center text-sm">
                {getActiveFiltersCount()}
              </span>
            )}
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setIsCreateDialogOpen(true)}
            className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Plus size={20} />
            <span>Create New Persona</span>
          </motion.button>
        </div>
      </div>

      <div className="space-y-8">
        {renderByCategory()}
      </div>

      {filteredBuyers.length === 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center py-12"
        >
          <p className="text-gray-500 text-lg">No personas match your filters</p>
        </motion.div>
      )}

      <CreateBuyerDialog 
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
      />

      <FilterDrawer
        isOpen={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        selectedCallType={selectedCallType}
        setSelectedCallType={setSelectedCallType}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        roleCategories={roleCategories}
      />
    </div>
  );
}