import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface LogoStore {
  logoUrl: string | null;
  setLogoUrl: (url: string | null) => void;
}

export const useLogoStore = create<LogoStore>()(
  persist(
    (set) => ({
      logoUrl: null,
      setLogoUrl: (url) => set({ logoUrl: url }),
    }),
    {
      name: 'logo-storage',
    }
  )
);