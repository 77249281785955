import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/useAuthStore';
import { Loader2 } from 'lucide-react';

export function VerifyEmailPage() {
  const navigate = useNavigate();
  const { verifyEmailLink } = useAuthStore();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const email = localStorage.getItem('emailForSignIn');
        if (!email) {
          setError('No email found. Please try signing in again.');
          setTimeout(() => navigate('/auth'), 3000);
          return;
        }

        const isNewUser = await verifyEmailLink(email);
        localStorage.removeItem('emailForSignIn');
        
        if (isNewUser) {
          navigate('/complete-profile');
        } else {
          navigate('/app');
        }
      } catch (error) {
        setError('Failed to verify email. Please try again.');
        setTimeout(() => navigate('/auth'), 3000);
      }
    };

    verifyEmail();
  }, [verifyEmailLink, navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <div className="text-red-600 mb-4">{error}</div>
          <p className="text-gray-600">Redirecting you to sign in...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <Loader2 className="animate-spin h-12 w-12 text-blue-600 mx-auto mb-4" />
        <h2 className="text-xl font-semibold text-gray-900">Verifying your email...</h2>
        <p className="mt-2 text-gray-600">Please wait while we complete the sign-in process.</p>
      </div>
    </div>
  );
}