export const VAPI_CONFIG = [
  {
    ASSISTANT_ID: 'dde4e11c-b6c8-4da8-8790-5980ff0bca2c',
    AGENT_NAME: 'Sarah Rodriguez',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
  },
  {
    ASSISTANT_ID: '2785b2a0-f696-48d0-a375-6aa480883912',
    AGENT_NAME: 'Michael Chen',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400',
  },
  {
    ASSISTANT_ID: '050019c8-ee2c-45ce-a1e0-3939b03ba6cd',
    AGENT_NAME: 'Emma Thompson',
    AGENT_IMAGE: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
  }
];

///

  export const VAPI_API_KEY ={
    PRIVATE_API_KEY:"6dfc43b0-7a09-499c-9c21-bc74ebda0eab",
    PUBLIC_API_KEY:"75cb64e3-b6fe-48f9-b1a6-bd80c7b2cc3f"
  }
  
  export const CALL_STATUS = {
    IDLE: 'idle',
    CONNECTING: 'connecting',
    ACTIVE: 'active',
    ENDED: 'ended',
    ERROR: 'error',
  };
  
  export const VAPI_EVENTS = {
    CALL_START: 'call-start',
    CALL_END: 'call-end',
    SPEECH_START: 'speech-start',
    SPEECH_END: 'speech-end',
    ERROR: 'error',
    TRANSCRIPT: 'message',
  };
  
  export const TRANSCRIPT_TYPES = {
    PARTIAL: 'partial',
    FINAL: 'final',
  };
  
  export const DEFAULTS = {
    CALL_TIMEOUT: 30000, 
    RETRY_DELAY: 5000, 
  };
  