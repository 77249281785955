import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, Loader2, Bot, ChevronRight } from 'lucide-react';

interface CreateBuyerDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CreateBuyerDialog({ isOpen, onClose }: CreateBuyerDialogProps) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsGenerating(true);
    // Simulate AI generation
    setTimeout(() => {
      setIsGenerating(false);
      onClose();
    }, 3000);
  };

  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />

        <div className="relative bg-white w-full max-w-2xl rounded-xl shadow-lg">
          {/* Header */}
          <div className="border-b border-gray-200 p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <button 
                  onClick={onClose}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <ArrowLeft size={20} />
                </button>
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-500">Buyer Bots</span>
                  <ChevronRight size={16} className="text-gray-400" />
                  <span className="text-sm text-gray-500">Create new</span>
                  <ChevronRight size={16} className="text-gray-400" />
                  <span className="text-sm text-gray-900">Cold Call</span>
                </div>
              </div>
              {isGenerating ? (
                <div className="flex items-center space-x-2 bg-gray-100 px-3 py-1.5 rounded-lg">
                  <Loader2 size={16} className="animate-spin text-gray-600" />
                  <span className="text-sm text-gray-600">Generating bot draft...</span>
                </div>
              ) : null}
            </div>
          </div>

          {/* Content */}
          <div className="p-6">
            <div className="flex items-start space-x-4 mb-8">
              <div className="p-3 bg-blue-50 rounded-lg">
                <Bot size={24} className="text-blue-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">AI Bot Creator</h3>
                <p className="text-gray-600">
                  Automatically creates a draft of your bot using AI
                </p>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Job Title <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  placeholder="The job title of the buyer you're targeting"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Describe your buyer and their situation <span className="text-red-500">*</span>
                </label>
                <p className="text-sm text-gray-500 mb-2">
                  Describe a specific scenario based on your target buyer profile. Doesn't have to be complete sentences.
                  (i.e. where they work, pain points, existing tools they use, common objections, etc.).
                </p>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
                <p className="mt-2 text-sm text-gray-500">
                  The more specific and less generic, the higher the quality of the bot.
                </p>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-gray-700 hover:text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isGenerating}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 flex items-center space-x-2"
                >
                  {isGenerating ? (
                    <>
                      <Loader2 size={16} className="animate-spin" />
                      <span>Generating...</span>
                    </>
                  ) : (
                    <span>Generate Bot</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Dialog>
  );
}