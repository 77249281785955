import React from 'react';
import { CircuitBoard } from 'lucide-react';

interface LogoProps {
  className?: string;
  isCollapsed?: boolean;
}

export function Logo({ className = '', isCollapsed = false }: LogoProps) {
  if (isCollapsed) {
    return (
      <div className={`flex justify-center ${className}`}>
        <CircuitBoard className="text-blue-600" size={24} />
      </div>
    );
  }

  return (
    <div className={`flex items-center ${className}`}>
      <div className="h-8 flex items-center">
        <div className="flex items-center">
          <CircuitBoard className="text-blue-600" size={24} />
          <div className="ml-2">
            <div className="font-bold text-xl text-gray-900 leading-none">
              AiCoach
            </div>
            <div className="text-xs text-gray-500">
              by ProjectA.ai
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}