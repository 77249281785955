import { create } from 'zustand';
import type { AuthState, User, UserRole } from '../types/auth';
import { auth, db, actionCodeSettings } from '../config/firebase';
import { 
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut as firebaseSignOut
} from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const ADMIN_EMAIL = 'rraj@growthpods.io';

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  loading: false,
  error: null,
  otpSent: false,
  
  setUser: (user) => set({ user }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  setOtpSent: (sent) => set({ otpSent: sent }),

  sendOTP: async (email: string) => {
    try {
      set({ loading: true, error: null });
      // Implement OTP sending logic here
      set({ otpSent: true, loading: false });
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Failed to send OTP',
        loading: false 
      });
      throw error;
    }
  },

  verifyOTP: async (email: string, otp: string) => {
    try {
      set({ loading: true, error: null });
      // Implement OTP verification logic here
      // Return true for new users, false for existing users
      return true;
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Failed to verify OTP',
        loading: false 
      });
      throw error;
    }
  },

  sendEmailLink: async (email: string) => {
    try {
      set({ loading: true, error: null });

      // Admin bypass
      if (email === ADMIN_EMAIL) {
        const adminUser: User = {
          id: 'admin-1',
          email: ADMIN_EMAIL,
          firstName: 'Raj',
          lastName: 'Admin',
          role: 'Founder',
          credits: 100,
          createdAt: new Date()
        };

        try {
          const userDoc = await getDoc(doc(db, 'users', 'admin-1'));
          if (!userDoc.exists()) {
            await setDoc(doc(db, 'users', 'admin-1'), adminUser);
          } else {
            await setDoc(doc(db, 'users', 'admin-1'), { ...userDoc.data(), credits: 100 }, { merge: true });
          }
        } catch (error) {
          console.warn('Failed to save admin user to Firestore:', error);
        }

        set({ user: adminUser, loading: false });
        return true;
      }

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      localStorage.setItem('emailForSignIn', email);
      set({ loading: false });
      return false;
    } catch (error) {
      console.error('Error sending email link:', error);
      set({ 
        error: error instanceof Error ? error.message : 'Failed to send verification email',
        loading: false 
      });
      throw error;
    }
  },

  verifyEmailLink: async (email: string) => {
    try {
      set({ loading: true, error: null });

      if (!isSignInWithEmailLink(auth, window.location.href)) {
        throw new Error('Invalid verification link');
      }

      const result = await signInWithEmailLink(auth, email, window.location.href);
      
      try {
        const userDoc = await getDoc(doc(db, 'users', result.user.uid));
        
        if (userDoc.exists()) {
          const userData = userDoc.data() as User;
          set({ user: userData, loading: false });
          return false;
        }
      } catch (error) {
        console.warn('Failed to check user profile:', error);
      }

      const tempUser: User = {
        id: result.user.uid,
        email: result.user.email!,
        credits: 50,
        createdAt: new Date()
      };
      set({ user: tempUser, loading: false });
      return true;
    } catch (error) {
      console.error('Error verifying email link:', error);
      set({ 
        error: error instanceof Error ? error.message : 'Failed to verify email',
        loading: false 
      });
      throw error;
    }
  },

  signOut: async () => {
    try {
      await firebaseSignOut(auth);
      set({ user: null });
    } catch (error) {
      console.error('Error signing out:', error);
      set({ user: null });
    }
  },

  completeProfile: async (data: { firstName: string; lastName: string; role: UserRole }) => {
    try {
      set({ loading: true, error: null });

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user');
      }

      const userData: User = {
        id: currentUser.uid,
        email: currentUser.email!,
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role,
        credits: 50,
        createdAt: new Date()
      };

      try {
        await setDoc(doc(db, 'users', userData.id), userData);
      } catch (error) {
        console.warn('Failed to save user profile to Firestore:', error);
      }

      set({ user: userData, loading: false });
    } catch (error) {
      console.error('Error completing profile:', error);
      set({ 
        error: error instanceof Error ? error.message : 'Failed to complete profile',
        loading: false 
      });
      throw error;
    }
  }
}));