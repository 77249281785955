import Vapi from '@vapi-ai/web';
import { v4 as uuidv4 } from 'uuid';
import { VAPI_CONFIG, CALL_STATUS ,VAPI_API_KEY} from '../components/VapiConstants';


export const vapi = new Vapi(VAPI_API_KEY.PUBLIC_API_KEY);


export class VapiService {
  private static instance: VapiService | null = null;
  private activeCallId: string | undefined = undefined;
  private isCallActive: boolean = false;
  private isMuted: boolean = false;
  private recordingUrl: string | undefined = undefined;
  private transcriptCallback: ((text: string, speaker: string) => void) | null = null;
  private currentTranscript: string = '';  
  private currentSpeaker: string = 'Unknown';  
  private latestTranscript: string = '';  

  
  constructor(onTranscript: (text: string, speaker: string) => void) {
    this.transcriptCallback = onTranscript;

    
    vapi.on('call-start', () => {
      console.log('Call started');
    });

    vapi.on('call-end', () => {
      console.log('Call ended');
      this.activeCallId = undefined;
    });

    vapi.on('speech-start', () => {
      console.log('Assistant started speaking');
    });

    vapi.on('speech-end', () => {
      console.log('Assistant stopped speaking');
    });

    vapi.on('error', (error) => {
      console.error('Vapi error:', error);
    });

  
    vapi.on('message', (msg) => {
      console.log('Received message:', msg);  
    
      if (msg.type !== 'transcript') return;
    
     
      if (msg.transcriptType === 'partial' || msg.transcriptType === 'final') {
        console.log('Transcript:', msg.transcript); 
        console.log('Speaker:', msg.speaker || 'Unknown');  

        
        this.latestTranscript = msg.transcript;
        this.currentSpeaker = msg.speaker || 'Unknown'; 
      }

      if (this.transcriptCallback) {
        this.transcriptCallback(this.latestTranscript, this.currentSpeaker);
      }
    });
  }


  public static getInstance(onTranscript: (text: string, speaker: string) => void): VapiService {
    if (!VapiService.instance) {
      VapiService.instance = new VapiService(onTranscript);
    }
    return VapiService.instance;
  }

  
  public getCurrentTranscript(): string {
    console.log("Returning latest transcript:", this.latestTranscript);
    return this.latestTranscript;
  }

  
  public getCurrentSpeaker(): string {
    return this.currentSpeaker;
  }

  
  public async startCall(assistantId: string): Promise<void> {
    try {
      console.log('Starting Vapi call');
      if (!vapi) throw new Error('Vapi instance is not initialized');
      await vapi.start(assistantId);
      this.isCallActive = true;
      this.activeCallId = uuidv4(); 
      console.log("Call id = ",this.activeCallId);
    } catch (error) {
      console.error('Failed to start call:', error);
      throw error;
    }
  }

  
  public async stopCall(): Promise<void> {
    try {
      console.log('Stopping Vapi call');
      if (!vapi) throw new Error('Vapi instance is not initialized');
      await vapi.stop();
      this.isCallActive = false;
    } catch (error) {
      console.error('Failed to stop call:', error);
      throw error;
    }
  }

 
  public toggleMute(): void {
    console.log("Muting User...")
    if (!vapi) {
      console.error('Vapi instance is not initialized');
      return;
    }
    this.isMuted = !this.isMuted;
    vapi.setMuted(this.isMuted);
    console.log(`Mute state changed to: ${this.isMuted}`);
  }


  public async cleanup(): Promise<void> {
    try {
      console.log('Cleaning up Vapi instance');
      if (this.isCallActive) {
        await this.stopCall();
      }
     
    } catch (error) {
      console.error('Cleanup error:', error);
    }
  }

  public getRecordingUrl(): string | undefined {
    return this.recordingUrl;
  }
}