import React from 'react';
import { motion } from 'framer-motion';
import { Globe, Phone, Users } from 'lucide-react';

interface BuyerFiltersProps {
  selectedCountry: string;
  setSelectedCountry: (country: string) => void;
  selectedCallType: string;
  setSelectedCallType: (type: string) => void;
  selectedRole: string;
  setSelectedRole: (role: string) => void;
  roleCategories: Record<string, string[]>;
}

const filters = {
  regions: [
    { id: 'all', name: 'All Regions', flag: '🌎' },
    { id: 'USA', name: 'USA', flag: '🇺🇸' },
    { id: 'Australia', name: 'AU', flag: '🇦🇺' },
    { id: 'India', name: 'IN', flag: '🇮🇳' }
  ],
  callTypes: [
    { id: 'all', name: 'All Calls' },
    { id: 'Cold Call', name: 'Cold' },
    { id: 'Warm Call', name: 'Warm' },
    { id: 'Gatekeeper Call', name: 'Gatekeeper' },
    { id: 'Event Invitation', name: 'Event' },
    { id: 'Webinar Invitation', name: 'Webinar' },
    { id: 'Check-In', name: 'Check-In' },
    { id: 'Renewal', name: 'Renewal' }
  ]
};

export function BuyerFilters({
  selectedCountry,
  setSelectedCountry,
  selectedCallType,
  setSelectedCallType,
  selectedRole,
  setSelectedRole,
  roleCategories
}: BuyerFiltersProps) {
  return (
    <div className="space-y-6">
      {/* Region Filter */}
      <div>
        <div className="flex items-center gap-2 text-gray-700 mb-3">
          <Globe className="w-5 h-5" />
          <span className="font-medium">Region</span>
        </div>
        <div className="flex flex-wrap gap-2">
          {filters.regions.map((region) => (
            <button
              key={region.id}
              onClick={() => setSelectedCountry(region.id)}
              className={`
                inline-flex items-center px-4 py-2 rounded-full text-sm transition-all
                ${selectedCountry === region.id
                  ? 'bg-blue-100 text-blue-600 font-medium'
                  : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
                }
              `}
            >
              <span className="mr-2">{region.flag}</span>
              {region.name}
            </button>
          ))}
        </div>
      </div>

      {/* Call Type Filter */}
      <div>
        <div className="flex items-center gap-2 text-gray-700 mb-3">
          <Phone className="w-5 h-5" />
          <span className="font-medium">Call Type</span>
        </div>
        <div className="flex flex-wrap gap-2">
          {filters.callTypes.map((type) => (
            <button
              key={type.id}
              onClick={() => setSelectedCallType(type.id)}
              className={`
                px-4 py-2 rounded-full text-sm transition-all
                ${selectedCallType === type.id
                  ? 'bg-blue-100 text-blue-600 font-medium'
                  : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
                }
              `}
            >
              {type.name}
            </button>
          ))}
        </div>
      </div>

      {/* Role Category Filter */}
      <div>
        <div className="flex items-center gap-2 text-gray-700 mb-3">
          <Users className="w-5 h-5" />
          <span className="font-medium">Role Category</span>
        </div>
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => setSelectedRole('all')}
            className={`
              px-4 py-2 rounded-full text-sm transition-all
              ${selectedRole === 'all'
                ? 'bg-blue-100 text-blue-600 font-medium'
                : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
              }
            `}
          >
            All Roles
          </button>
          {Object.keys(roleCategories).map((category) => (
            <button
              key={category}
              onClick={() => setSelectedRole(category)}
              className={`
                px-4 py-2 rounded-full text-sm transition-all
                ${selectedRole === category
                  ? 'bg-blue-100 text-blue-600 font-medium'
                  : 'bg-gray-50 text-gray-600 hover:bg-gray-100'
                }
              `}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}