import React, { useState } from 'react';
import { Phone, ChevronDown, Clock, User, Mic, MicOff } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const countries = [
  { code: '+1', name: 'USA' },
  { code: '+44', name: 'UK' },
  { code: '+91', name: 'India' },
  { code: '+61', name: 'Australia' },
];

export function LiveCallPage() {
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return '';
    const number = value.replace(/\D/g, '');
    if (number.length <= 3) return number;
    if (number.length <= 6) return `${number.slice(0, 3)}-${number.slice(3)}`;
    return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6, 10)}`;
  };

  const handleCall = () => {
    if (phoneNumber.length === 10) {
      setIsCallActive(true);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {!isCallActive ? (
        <div className="min-h-[400px] bg-[#2A3441] rounded-b-[32px] px-6 py-12">
          <div className="max-w-2xl mx-auto text-center">
            <h1 className="text-3xl font-semibold text-gray-200 mb-12">
              Make a call and start speaking
            </h1>

            <div className="space-y-4">
              <div className="text-left">
                <label className="block text-gray-400 text-sm mb-2">
                  Phone number
                </label>
                <div className="flex rounded-lg overflow-hidden bg-[#1F2937]">
                  <div className="relative">
                    <button
                      onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                      className="flex items-center space-x-2 bg-[#374151] text-white px-6 py-4 hover:bg-opacity-80 transition-colors min-w-[140px] justify-between"
                    >
                      <span>{selectedCountry.code}</span>
                      <ChevronDown size={16} className="text-gray-400" />
                    </button>

                    <AnimatePresence>
                      {isCountryDropdownOpen && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="absolute top-full left-0 mt-1 w-full bg-white rounded-lg shadow-lg py-1 z-50"
                        >
                          {countries.map((country) => (
                            <button
                              key={country.code}
                              onClick={() => {
                                setSelectedCountry(country);
                                setIsCountryDropdownOpen(false);
                              }}
                              className="w-full px-4 py-3 text-left hover:bg-gray-50 text-gray-700"
                            >
                              {country.name} ({country.code})
                            </button>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  <input
                    type="tel"
                    value={formatPhoneNumber(phoneNumber)}
                    onChange={handlePhoneNumberChange}
                    placeholder="Enter 10 digit number"
                    className="flex-1 bg-transparent text-white px-6 py-4 focus:outline-none focus:ring-0 text-lg placeholder-gray-500"
                  />
                </div>
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleCall}
                disabled={phoneNumber.length !== 10}
                className="mt-8 bg-[#3B82F6] text-white px-12 py-3 rounded-full flex items-center justify-center space-x-2 hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed mx-auto text-lg font-medium"
              >
                <Phone size={20} />
                <span>Call</span>
              </motion.button>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-6 max-w-7xl mx-auto space-y-6">
          {/* Call Info Card */}
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="grid grid-cols-4 gap-8">
              <div>
                <div className="text-sm font-medium text-gray-500 mb-1">Owner</div>
                <div className="text-gray-900">Raj</div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500 mb-1">Date & Time</div>
                <div className="text-gray-900 animate-pulse bg-gray-100 h-6 w-32 rounded"></div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500 mb-1">Duration</div>
                <div className="text-gray-900 animate-pulse bg-gray-100 h-6 w-24 rounded"></div>
              </div>
              <div>
                <div className="text-sm font-medium text-gray-500 mb-1">Speaker</div>
                <div className="text-gray-900 animate-pulse bg-gray-100 h-6 w-40 rounded"></div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-2 space-y-6">
              {/* Summary Section */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Summary</h2>
                <div className="space-y-2">
                  <div className="animate-pulse bg-gray-100 h-4 w-3/4 rounded"></div>
                  <div className="animate-pulse bg-gray-100 h-4 w-2/3 rounded"></div>
                  <div className="animate-pulse bg-gray-100 h-4 w-1/2 rounded"></div>
                </div>
              </div>

              {/* Topics Section */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Topics</h2>
                <div className="flex flex-wrap gap-2">
                  {[1, 2, 3, 4].map((_, i) => (
                    <div key={i} className="animate-pulse bg-gray-100 h-8 w-24 rounded-full"></div>
                  ))}
                </div>
              </div>

              {/* Transcription Section */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-xl font-semibold text-gray-900 flex items-center">
                  Transcription
                  <span className="text-sm font-normal text-gray-500 ml-2">
                    (with Sentiment Analysis)
                  </span>
                </h2>
                <div className="mt-6 space-y-4">
                  <div className="animate-pulse bg-gray-100 h-20 rounded-lg"></div>
                  <div className="animate-pulse bg-gray-100 h-20 rounded-lg"></div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-6">
              {/* Call Controls */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex justify-center space-x-4">
                  <button
                    onClick={() => setIsMuted(!isMuted)}
                    className={`p-4 rounded-full transition-colors ${
                      isMuted ? 'bg-red-100 text-red-600' : 'bg-gray-100 text-gray-600'
                    }`}
                  >
                    {isMuted ? <MicOff size={24} /> : <Mic size={24} />}
                  </button>
                  <button
                    onClick={() => setIsCallActive(false)}
                    className="p-4 rounded-full bg-red-600 text-white hover:bg-red-700 transition-colors"
                  >
                    <Phone size={24} />
                  </button>
                </div>
              </div>

              {/* Insights Panel */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-semibold text-gray-900">All Insights</h2>
                  <button className="text-gray-400 hover:text-gray-600 transition-colors">
                    <ChevronDown size={20} />
                  </button>
                </div>
                <div className="space-y-4">
                  {[1, 2, 3].map((_, i) => (
                    <div key={i} className="animate-pulse bg-gray-100 h-20 rounded-lg"></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}