import React, { useState } from 'react';
import { Mail, Loader2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuthStore } from '../../store/useAuthStore';
import { useNavigate } from 'react-router-dom';

export function LoginForm() {
  const [email, setEmail] = useState('');
  const { loading, error, sendEmailLink } = useAuthStore();
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const isAdmin = await sendEmailLink(email);
      if (isAdmin) {
        navigate('/app');
      } else {
        setEmailSent(true);
      }
    } catch (error) {
      // Error is handled by the store
    }
  };

  if (emailSent) {
    return (
      <div className="text-center">
        <h3 className="text-xl font-semibold text-gray-900 mb-4">Check your email</h3>
        <p className="text-gray-600 mb-6">
          We've sent a sign-in link to <strong>{email}</strong>
        </p>
        <button
          onClick={() => setEmailSent(false)}
          className="text-blue-600 hover:text-blue-700"
        >
          Use a different email
        </button>
      </div>
    );
  }

  return (
    <div className="w-full max-w-md">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-gray-900">Welcome to ProjectA</h2>
        <p className="mt-2 text-gray-600">
          Enter your work email to get started
        </p>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-lg text-sm">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Work Email
          </label>
          <div className="mt-1 relative">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="you@company.com"
              required
            />
            <Mail className="absolute left-3 top-2.5 text-gray-400" size={20} />
          </div>
        </div>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          type="submit"
          disabled={loading}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
        >
          {loading ? (
            <Loader2 className="animate-spin" size={20} />
          ) : (
            <Mail size={20} />
          )}
          <span>Send Sign-in Link</span>
        </motion.button>

        <p className="text-center text-sm text-gray-500">
          We'll send you a magic link to sign in instantly
        </p>
      </form>
    </div>
  );
}