import { MAX_FILE_SIZE } from '../config/constants';

export class LogoService {
  static async uploadLogo(file: File): Promise<string> {
    if (file.size > MAX_FILE_SIZE) {
      throw new Error('File size exceeds 2MB limit');
    }

    try {
      const formData = new FormData();
      formData.append('logo', file);

      const response = await fetch('/api/logo/upload', {
        method: 'POST',
        body: formData,
      });

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Invalid server response');
      }

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to upload logo');
      }

      return data.url;
    } catch (error) {
      console.error('Upload error:', error);
      throw error instanceof Error ? error : new Error('Failed to upload logo');
    }
  }

  static async deleteLogo(filename: string): Promise<void> {
    try {
      const response = await fetch(`/api/logo/${filename}`, {
        method: 'DELETE',
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to delete logo');
      }
    } catch (error) {
      console.error('Delete error:', error);
      throw error instanceof Error ? error : new Error('Failed to delete logo');
    }
  }
}