import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface CreditsStore {
  credits: number;
  monthlyLimit: number;
  isCallActive: boolean;
  renewalDate: string;
  deductCredits: (amount: number) => void;
  addCredits: (amount: number) => void;
  setCallActive: (active: boolean) => void;
  purchaseCredits: (amount: number) => void;
  upgradePlan: (newLimit: number) => void;
}

export const useCreditsStore = create<CreditsStore>()(
  persist(
    (set) => ({
      credits: 30,
      monthlyLimit: 50,
      isCallActive: false,
      renewalDate: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString(),
      deductCredits: (amount) =>
        set((state) => ({ credits: Math.max(0, state.credits - amount) })),
      addCredits: (amount) =>
        set((state) => ({ credits: Math.min(state.monthlyLimit, state.credits + amount) })),
      setCallActive: (active) =>
        set({ isCallActive: active }),
      purchaseCredits: (amount) =>
        set((state) => ({ credits: state.credits + amount })),
      upgradePlan: (newLimit) =>
        set({ monthlyLimit: newLimit }),
    }),
    {
      name: 'credits-storage',
    }
  )
);