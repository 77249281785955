import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Initialize web-vitals
const reportWebVitals = (onPerfEntry?: (metric: any) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

// Custom hook for page view tracking
export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page view
    const page_path = location.pathname + location.search;
    
    // Example tracking code - replace with your analytics service
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', {
        page_path,
        page_title: document.title
      });
    }
  }, [location]);
};

export { reportWebVitals };