import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { BuyerFilters } from './BuyerFilters';

interface FilterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCountry: string;
  setSelectedCountry: (country: string) => void;
  selectedCallType: string;
  setSelectedCallType: (type: string) => void;
  selectedRole: string;
  setSelectedRole: (role: string) => void;
  roleCategories: Record<string, string[]>;
}

export function FilterDrawer({
  isOpen,
  onClose,
  selectedCountry,
  setSelectedCountry,
  selectedCallType,
  setSelectedCallType,
  selectedRole,
  setSelectedRole,
  roleCategories
}: FilterDrawerProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
          />

          {/* Drawer */}
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', damping: 30, stiffness: 300 }}
            className="fixed right-0 top-0 h-full w-96 bg-white shadow-xl z-50 overflow-y-auto"
          >
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-semibold text-gray-900">Filters</h2>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                >
                  <X size={20} className="text-gray-500" />
                </button>
              </div>

              <BuyerFilters
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedCallType={selectedCallType}
                setSelectedCallType={setSelectedCallType}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                roleCategories={roleCategories}
              />

              <div className="mt-8 flex space-x-4">
                <button
                  onClick={() => {
                    setSelectedCountry('all');
                    setSelectedCallType('all');
                    setSelectedRole('all');
                  }}
                  className="flex-1 px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  Reset All
                </button>
                <button
                  onClick={onClose}
                  className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Apply Filters
                </button>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}