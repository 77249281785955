import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Phone,
  Users,
  History,
  BookOpen,
  Settings,
  LogOut,
  ChevronLeft,
  ChevronRight,
  Coins,
  CircuitBoard
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useCreditsStore } from '../store/useCreditsStore';
import { useAuthStore } from '../store/useAuthStore';

const menuItems = [
  { icon: Users, label: 'AI Personas', path: '/app/buyers' },
  { icon: Phone, label: 'Live Call', path: '/app/calls' },
  { icon: History, label: 'Call History', path: '/app/history' },
  { icon: BookOpen, label: 'Training Material', path: '/app/training' },
];

export function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { credits, monthlyLimit } = useCreditsStore();
  const { user, signOut } = useAuthStore();
  
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleSignOut = async () => {
    await signOut();
  };

  return (
    <motion.div 
      initial={{ x: -300 }}
      animate={{ 
        x: 0,
        width: isCollapsed ? 80 : 280
      }}
      transition={{ duration: 0.3 }}
      className="relative h-screen bg-gray-100 flex flex-col"
    >
      {/* Toggle Button */}
      <button
        onClick={toggleSidebar}
        className="absolute -right-3 top-6 bg-white border border-gray-200 rounded-full p-1 hover:bg-gray-50 transition-colors z-50"
      >
        {isCollapsed ? (
          <ChevronRight size={16} className="text-gray-600" />
        ) : (
          <ChevronLeft size={16} className="text-gray-600" />
        )}
      </button>

      {/* Logo */}
      <div className={`p-6 ${isCollapsed ? 'px-4' : ''}`}>
        <div className="flex items-center space-x-3">
          <CircuitBoard className="text-blue-600" size={28} />
          <AnimatePresence>
            {!isCollapsed && (
              <motion.div
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 'auto' }}
                exit={{ opacity: 0, width: 0 }}
                className="overflow-hidden whitespace-nowrap"
              >
                <div className="font-bold text-xl text-gray-900">AiCoach</div>
                <div className="text-xs text-gray-500">by ProjectA.ai</div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* Navigation */}
      <nav className="flex-1 px-4 py-4">
        {menuItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className={({ isActive }) =>
              `flex items-center space-x-3 px-4 py-3 rounded-xl transition-all duration-200 mb-1 ${
                isActive 
                  ? 'bg-blue-50 text-blue-600' 
                  : 'text-gray-600 hover:text-blue-600 hover:bg-gray-50'
              }`
            }
          >
            {({ isActive }) => (
              <>
                <item.icon size={20} />
                <AnimatePresence>
                  {!isCollapsed && (
                    <motion.span
                      initial={{ opacity: 0, width: 0 }}
                      animate={{ opacity: 1, width: 'auto' }}
                      exit={{ opacity: 0, width: 0 }}
                      className="font-medium text-sm whitespace-nowrap overflow-hidden"
                    >
                      {item.label}
                    </motion.span>
                  )}
                </AnimatePresence>
                {isActive && (
                  <motion.div
                    layoutId="active-pill"
                    className="absolute left-0 w-1 h-8 bg-blue-600 rounded-r-full"
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  />
                )}
              </>
            )}
          </NavLink>
        ))}
      </nav>

      {/* Bottom Section */}
      <div className="mt-auto px-4 py-4 space-y-4 border-t border-gray-200">
        {/* User Profile */}
        <div className={`${isCollapsed ? 'text-center' : ''}`}>
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0">
              <img
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(user?.firstName || '')}+${encodeURIComponent(user?.lastName || '')}&background=3B82F6&color=fff`}
                alt={user?.firstName || 'User'}
                className="h-10 w-10 rounded-lg"
              />
            </div>
            <AnimatePresence>
              {!isCollapsed && (
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  exit={{ opacity: 0, width: 0 }}
                  className="overflow-hidden"
                >
                  <div className="text-sm font-medium text-gray-900">
                    {user?.firstName} {user?.lastName}
                  </div>
                  <div className="text-xs text-gray-500">{user?.email}</div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>

        {/* Credits */}
        <div className={`${isCollapsed ? 'text-center' : ''}`}>
          <div className={`flex items-center ${isCollapsed ? 'justify-center' : 'space-x-3'}`}>
            <Coins className="text-yellow-500" size={20} />
            <AnimatePresence>
              {!isCollapsed && (
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  exit={{ opacity: 0, width: 0 }}
                  className="overflow-hidden whitespace-nowrap"
                >
                  <div className="text-sm font-medium text-gray-900">
                    {credits} / {monthlyLimit} credits
                  </div>
                  <div className="w-full h-1.5 bg-gray-200 rounded-full mt-2">
                    <div 
                      className="h-full bg-blue-600 rounded-full"
                      style={{ width: `${(credits / monthlyLimit) * 100}%` }}
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>

        {/* Settings & Logout */}
        <div className="space-y-1">
          <NavLink
            to="/app/settings"
            className={({ isActive }) =>
              `flex items-center space-x-3 px-4 py-3 rounded-xl transition-all duration-200 ${
                isActive 
                  ? 'bg-blue-50 text-blue-600' 
                  : 'text-gray-600 hover:text-blue-600 hover:bg-gray-50'
              }`
            }
          >
            <Settings size={20} />
            <AnimatePresence>
              {!isCollapsed && (
                <motion.span
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  exit={{ opacity: 0, width: 0 }}
                  className="font-medium text-sm whitespace-nowrap"
                >
                  Settings
                </motion.span>
              )}
            </AnimatePresence>
          </NavLink>

          <button
            onClick={handleSignOut}
            className="flex items-center space-x-3 px-4 py-3 w-full rounded-xl text-gray-600 hover:text-blue-600 hover:bg-gray-50 transition-all duration-200"
          >
            <LogOut size={20} />
            <AnimatePresence>
              {!isCollapsed && (
                <motion.span
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  exit={{ opacity: 0, width: 0 }}
                  className="font-medium text-sm whitespace-nowrap"
                >
                  Sign Out
                </motion.span>
              )}
            </AnimatePresence>
          </button>
        </div>
      </div>
    </motion.div>
  );
}