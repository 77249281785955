import { create } from 'zustand';
import type { Buyer, CallSession } from '../types';

interface Store {
  buyers: Buyer[];
  sessions: CallSession[];
  currentBuyer: Buyer | null;
  setCurrentBuyer: (buyer: Buyer | null) => void;
  addSession: (session: CallSession) => void;
}

export const useStore = create<Store>((set) => ({
  buyers: [
    // Sales Personas
    {
      id: '1',
      name: 'Sarah Rodriguez',
      title: 'CIO',
      company: 'BioLife Sciences',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
      callType: 'Warm Call',
      personality: ['Strategic', 'Innovation Focused', 'Data-Driven'],
      metrics: {
        decisionTime: '2-3 weeks',
        budgetRange: '$100k-$500k',
        stakeholders: 4,
        successRate: 68
      },
      isOnline: true,
      country: 'USA'
    },
    {
      id: '2',
      name: 'Michael Chen',
      title: 'VP of IT',
      company: 'RetailGiant Corp',
      avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400',
      callType: 'Cold Call',
      personality: ['Analytical', 'ROI Focused', 'Process-Oriented'],
      metrics: {
        decisionTime: '4-6 weeks',
        budgetRange: '$50k-$200k',
        stakeholders: 3,
        successRate: 45
      },
      isOnline: true,
      country: 'USA'
    },
    // HR Personas
    {
      id: '3',
      name: 'Emma Thompson',
      title: 'Head of HR',
      company: 'TechForward Solutions',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
      callType: 'Performance Review',
      personality: ['Empathetic', 'Development-Focused', 'Policy-Driven'],
      metrics: {
        timeToHire: '45 days',
        retentionRate: '92%',
        employeeNPS: 8.5,
        successRate: 75
      },
      isOnline: false,
      country: 'USA'
    },
    // Customer Service Personas
    {
      id: '4',
      name: 'David Cooper',
      title: 'Support Team Lead',
      company: 'ServiceFirst Inc',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400',
      callType: 'Customer Escalation',
      personality: ['Patient', 'Solution-Oriented', 'Customer-Centric'],
      metrics: {
        responseTime: '< 2 hours',
        satisfactionScore: 4.8,
        resolutionRate: '94%',
        successRate: 88
      },
      isOnline: true,
      country: 'USA'
    },
    // Education Personas
    {
      id: '5',
      name: 'Sophie Mitchell',
      title: 'Education Coordinator',
      company: 'EduTech Learning',
      avatar: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=400',
      callType: 'Student Guidance',
      personality: ['Supportive', 'Goal-Oriented', 'Detail-Focused'],
      metrics: {
        studentSuccess: '89%',
        programCompletion: '92%',
        satisfactionRate: 4.7,
        successRate: 82
      },
      isOnline: true,
      country: 'USA'
    },
    // Recruitment Personas
    {
      id: '6',
      name: 'James Wilson',
      title: 'Technical Recruiter',
      company: 'TalentHub Solutions',
      avatar: 'https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?w=400',
      callType: 'Technical Interview',
      personality: ['Analytical', 'Assessment-Focused', 'Communication-Driven'],
      metrics: {
        offerAcceptRate: '78%',
        timeToOffer: '21 days',
        candidateNPS: 8.8,
        successRate: 71
      },
      isOnline: false,
      country: 'USA'
    }
  ],
  sessions: [
    {
      id: '1',
      buyerId: '1',
      startTime: new Date('2024-04-22T17:41:00'),
      endTime: new Date('2024-04-22T17:41:42'),
      analysis: {
        sentiment: 85,
        keywords: ['innovation', 'efficiency', 'cost-saving'],
        suggestions: ['Improve value proposition', 'Follow up on ROI discussion']
      }
    },
    {
      id: '2',
      buyerId: '2',
      startTime: new Date('2024-04-21T13:54:00'),
      endTime: new Date('2024-04-21T13:54:31'),
      analysis: {
        sentiment: 72,
        keywords: ['budget', 'implementation', 'timeline'],
        suggestions: ['Address budget concerns', 'Provide implementation roadmap']
      }
    },
    {
      id: '3',
      buyerId: '3',
      startTime: new Date('2024-04-20T13:57:00'),
      endTime: new Date('2024-04-20T13:57:17'),
      analysis: {
        sentiment: 68,
        keywords: ['development', 'training', 'goals'],
        suggestions: ['Focus on growth opportunities', 'Discuss career path']
      }
    }
  ],
  currentBuyer: null,
  setCurrentBuyer: (buyer) => set({ currentBuyer: buyer }),
  addSession: (session) => set((state) => ({ sessions: [...state.sessions, session] })),
}));