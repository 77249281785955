import React from 'react';
import { LoginForm } from '../components/auth/LoginForm';
import { Logo } from '../components/Logo';
import { useAuthStore } from '../store/useAuthStore';
import { Navigate } from 'react-router-dom';

export function AuthPage() {
  const { user } = useAuthStore();

  if (user) {
    return <Navigate to="/app" replace />;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 px-4">
      <div className="mb-8">
        <Logo />
      </div>
      <LoginForm />
    </div>
  );
}