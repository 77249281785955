import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Something went wrong</h2>
        <div className="text-gray-600 mb-4">
          {import.meta.env.DEV ? (
            <pre className="text-sm bg-gray-100 p-4 rounded overflow-auto">
              {error.message}
              {'\n'}
              {error.stack}
            </pre>
          ) : (
            <p>An unexpected error occurred. Our team has been notified.</p>
          )}
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 text-gray-600 hover:text-gray-900"
          >
            Refresh Page
          </button>
          <button
            onClick={resetErrorBoundary}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
}

export function AppErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        console.error('Error:', error);
        console.error('Component Stack:', info.componentStack);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}